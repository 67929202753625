import { HELPERS } from '../classes/helpers';

export default {
  init() {
    // JavaScript to be fired on all pages

    // header search form trigger
    let searchForm = $(document).find('header .search-form');
    $('.trigger', searchForm).on('click', function() {
      $(searchForm).toggleClass('active');
    });

    // Slow scroll with anchors
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[data-toggle="tab"]').click(function (event) {
      if(!$(this).closest('.wc-tabs' ).length) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length > 0) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        }
      }
    });

    //Back to top button
    let toTopButton = document.getElementById("btn-back-to-top");
    
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        toTopButton.style.display = "block";
      } else {
        toTopButton.style.display = "none";
      }
    }
    // When the user clicks on the button, scroll to the top of the document
    toTopButton.addEventListener("click", backToTop);

    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }


    // Animations with animate.css
    function callbackFunc(entries, observer)
    {
      entries.forEach(entry => {
        let $e = $(entry.target);
        if(entry.isIntersecting) {
          let $animation_e = $e;
          let animation = $animation_e.data('animation');
          
          $e.addClass('animate_start ' + animation);
        }
      });
    }
    
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.15
    };
    
    let observer = new IntersectionObserver(callbackFunc, options);
    
    let $animation_elements = $('.animate__animated');

    $animation_elements.each(function( e ) {
      observer.observe($(this)[0]);
    });

    // site notice
    $('.site-notice .close-notice').on('click', function() {
      $('.site-notice').slideUp();
      HELPERS.createCookie('site_notice', 1, 7);
    });

    if(HELPERS.readCookie('site_notice') === null) {
      $('.site-notice').removeClass('d-none');
    } 

    if($(document).find('.parallax').length > 0) {
      $('.main > div').each(function( index ) {
        if($(this).find('.parallax').length > 0 || $(this).hasClass('parallax')) {
          $(this).add('position', 'static');
        } else {
          $(this).addClass('no-parallax');
        }
      });
    }
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};