import { HELPERS } from '../classes/helpers';

let $popup = $(document).find('#global-popup');

if($popup.length > 0) {
  let interval = $popup.data('interval');
  let show = $popup.data('show-after');

  //show popup on page load
  setTimeout(function(){
    if(HELPERS.readCookie('global_popup') == null) {
      $popup.modal('show');
      if(interval > 0)
        HELPERS.createCookie('global_popup', 1, interval);
    }
  }, show);
  
  $popup.on('hide.bs.modal', function () {
    if(interval > 0)
      HELPERS.createCookie('global_popup', 1, interval);
  });
}